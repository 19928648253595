import store from "@/store";
import { useRouter } from "@core/utils/utils";

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInCart = (productId) => {
    return store.dispatch("purchaseModule/addBasketItem", productId);
  };

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = (productId) => {
    return store.dispatch("purchaseModule/removeProductFromCart", {
      productId,
    });
  };

  return {
    addProductInCart,
    removeProductFromCart,
  };
};

export const useEcommerceUi = () => {
  const { router } = useRouter();

  const handleCartActionClick = (product) => {
    const { addProductInCart } = useEcommerce();
    addProductInCart(product);
  };

  return {
    handleCartActionClick,
  };
};
